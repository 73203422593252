import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import TiltShape from '../TiltShape';
import { BannerWrapper, DiscountWrapper, DiscountLabel } from './banner.style';
import { ic_play_circle_filled } from 'react-icons-kit/md/ic_play_circle_filled';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import Vimeo from '@u-wave/react-vimeo';
import VimeoVideoSection from '../VimeoVideo';

const BannerSection = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  imageWrapper,
  buttonWrapper,
  button,
  fillButton,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "image/saasModern/mockuprandevualhome.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const [isOpen, setOpen] = useState(false);
  return (
    <BannerWrapper id="banner_section">
      <TiltShape />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            {/*<DiscountWrapper>*/}
            {/*  <DiscountLabel>*/}
            {/*    <Text {...discountAmount} content="7 Günlük" />*/}
            {/*    <Text*/}
            {/*      {...discountText}*/}
            {/*      content="Ücretsiz Denemenizi Kaçırmayın"*/}
            {/*    />*/}
            {/*  </DiscountLabel>*/}
            {/*</DiscountWrapper>*/}
            <Heading
              {...title}
              content="Increase Your Show-Up Rate and Get New Customers Without Ad Spend"
            />
            <Text
              {...description}
              content="Appomark is the first-ever personalized appointment scheduling and CRM software that will remind your appointments by voice call, SMS, and email."
            />
            <Box {...buttonWrapper} style={{ marginBottom: 20 }}>
              <a href="https://platform.appomark.com/register">
                <Button {...fillButton} title="7 DAY FREE TRIAL" />
              </a>
              <Button
                {...button}
                onClick={() => setOpen(true)}
                title="HOW IT WORKS?"
                icon={<Icon icon={ic_play_circle_filled} size={30} />}
                iconPosition="left"
              />
              <Modal
                width={800}
                title=""
                visible={isOpen}
                onCancel={() => setOpen(false)}
                footer={[
                  <Button
                    {...fillButton}
                    title="7 DAY FREE TRIAL"
                    onClick={() =>
                      (window.location.href = 'https://platform.appomark.com')
                    }
                  />,
                ]}
              >
                <Vimeo video="798354517" responsive={true} />
              </Modal>
            </Box>
            {/* <VimeoVideoSection
              content={'Voice Call Feature'}
              videoId={697325155}
              titleTextColor={'white'}
            /> */}
            {/* <Vimeo video="697325155" responsive={true} /> */}
          </Box>
          <Box {...imageWrapper}>
            <Fade bottom>
              <Image
                fluid={Data.bannerImage.childImageSharp.fluid}
                alt="banner image"
                style={{ marginTop: -50 }}
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  imageWrapper: {
    width: '100%',
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#ff0000',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['11px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: '#2aa275',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default BannerSection;
