import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import ProcessItem, { ProcessIndex } from './embed_appointment.style';
import Button from 'common/components/Button';
import { Button as AntdButton, Form, Input, Modal, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';

const EmbedAppointmentSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  buttonWrapper,
  button,
}) => {
  const [isOpen, setOpen] = useState(false);
  const isBrowser = () => typeof window !== 'undefined';
  let mediaMatch = {
    matches: true,
  };
  if (isBrowser()) {
    mediaMatch = window.matchMedia('(min-width: 500px)');
  }
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return (
    <Box {...sectionWrapper} as="section" id="embed_appointment_section">
      <Container>
        <Box {...secTitleWrapper}>
          {/* <Text {...secText} content="BOOK" /> */}
          <div style={styles.container(matches)}>
            <iframe
              src="https://api.appomark.com/appointments/create/appomark"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </div>
        </Box>
      </Container>
    </Box>
  );
};

const styles = {
  container: (isRowBased) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: isRowBased ? '76.25%' : '86.25%',
    paddingBottom: isRowBased ? '0' : '76.25%',
  }),
};

EmbedAppointmentSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  buttonWrapper: PropTypes.object,
};

EmbedAppointmentSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    // mb: ['60px', '50px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  buttonWrapper: {
    mt: '15px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default EmbedAppointmentSection;
