import React, { useContext, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Button from 'common/components/Button';
import Logo from 'common/components/UIElements/Logo';
import Box from 'common/components/Box';
import HamburgMenu from 'common/components/HamburgMenu';
import Container from 'common/components/UI/Container';
import { DrawerContext } from 'common/contexts/DrawerContext';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';

import LogoImage from 'common/assets/image/saasModern/logo-white.png';
import LogoImageAlt from 'common/assets/image/saasModern/logo-footer.png';
import { Button as AntdButton, Form, Input, Modal, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  const [openBizeUlasin, setOpenBizeUlasin] = useState(false);
  const onFinishContact = (values) => {
    axios
      .post('https://api.appomark.com/api/auth/send-contact', { ...values })
      .then((response) => {
        if (response.data.code === 1) {
          notification.success({
            message: 'Successfull!',
            description: 'We will contact you asap!',
            placement: 'bottomRight',
          });
          setOpenBizeUlasin(false);
        } else {
          notification.error({
            message: 'Failed!',
            description:
              'We are currently experiencing a problem. Please try again later!',
            placement: 'bottomRight',
          });
          setOpenBizeUlasin(false);
        }
      });
  };
  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Portfolio"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.saasModernJson.MENU_ITEMS}
              offset={-70}
            />
            <a href="https://platform.appomark.com/" className="navbar_button">
              <Button
                style={{
                  marginRight: '10px',
                  fontSize: '13px',
                  fontWeight: '550',
                  borderRadius: '4px',
                  pl: '15px',
                  pr: '15px',
                  minHeight: 'auto',
                  height: '40px',
                  backgroundColor: '#edcd37',
                  color: 'black',
                }}
                title="Login"
              />
            </a>
            {/*<a*/}
            {/*  href="https://platform.appomark.com/register"*/}
            {/*  className="navbar_button"*/}
            {/*>*/}
            {/*  <Button {...button} title="7 GÜNLÜK DENEMEYİ BAŞLAT!" />*/}
            {/*</a>*/}
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <a
                href="https://platform.appomark.com/"
                className="navbar_drawer_button"
                style={{ marginBottom: '25px' }}
              >
                <Button {...button} title="START 7 DAY TRIAL!" />
              </a>
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.saasModernJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
                style={{ marginBottom: '-60px' }}
              />
              <a
                href="#contact_us"
                className="mobile_menu"
                style={{
                  color: '#343d48',
                  marginTop: '-30px',
                  fontSize: '17px',
                }}
                onClick={() => setOpenBizeUlasin(true)}
              >
                Contact Us
              </a>
              <a
                href="https://platform.appomark.com/"
                className="navbar_button"
              >
                <Button
                  style={{
                    fontSize: '13px',
                    fontWeight: '550',
                    borderRadius: '4px',
                    minHeight: 'auto',
                    height: '40px',
                    backgroundColor: '#edcd37',
                    color: 'black',
                  }}
                  title="Login"
                />
              </a>
            </Drawer>
          </Box>
        </Box>
        <Modal
          width={600}
          title="Contact Us"
          visible={openBizeUlasin}
          onCancel={() => setOpenBizeUlasin(false)}
          footer={null}
        >
          <Form name="basic" onFinish={onFinishContact}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter your name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone number!',
                },
              ]}
            >
              <PhoneInput
                country={'us'}
                enableSearch={true}
                countryCodeEditable={false}
              />
            </Form.Item>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: 'Please fill it!' }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <AntdButton type="primary" htmlType="submit">
                Send
              </AntdButton>
            </Form.Item>
          </Form>
          <br />
          <span style={{ fontWeight: 'bold' }}>E-Mail</span>:{' '}
          <a href="mailto:info@appomark.com">info@appomark.com</a>
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>Address</span>: 312 W 2nd St Unit
          #A1604 Casper, WY 82601, United States
        </Modal>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
