import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import ProcessItem, { ProcessIndex } from './hakkimizda.style';
import Button from 'common/components/Button';
import { Button as AntdButton, Form, Input, Modal, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';

const HakkimizdaSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  buttonWrapper,
  button,
}) => {
  const [isOpen, setOpen] = useState(false);
  const onFinishContact = (values) => {
    axios
      .post('https://api.appomark.com/api/auth/send-contact', { ...values })
      .then((response) => {
        if (response.data.code === 1) {
          notification.success({
            message: 'Successfull!',
            description: 'We will contact you asap!',
            placement: 'bottomRight',
          });
          setOpen(false);
        } else {
          notification.error({
            message: 'Failed!',
            description:
              'We are currently experiencing a problem. Please try again later!',
            placement: 'bottomRight',
          });
          setOpen(false);
        }
      });
  };
  return (
    <Box {...sectionWrapper} as="section" id="hakkimizda_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="ABOUT US" />
          <Heading
            {...secHeading}
            content="We are working to grow your business with more than 10 years of experience in internet services and a professional team. We have developed products and services that will make you unrivaled in your industry and take your business to the next level, in line with the demands we receive from you. We help you manage and grow your business with Appomark software."
          />
          <Box {...buttonWrapper}>
            <Button
              {...button}
              onClick={() => setOpen(true)}
              title="Contact Us!"
            />
            <Modal
              width={600}
              title="Contact Us!"
              visible={isOpen}
              onCancel={() => setOpen(false)}
              footer={null}
            >
              <Form name="basic" onFinish={onFinishContact}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: 'Please enter your name!' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone number!',
                    },
                  ]}
                >
                  <PhoneInput
                    country={'us'}
                    enableSearch={true}
                    countryCodeEditable={false}
                  />
                </Form.Item>
                <Form.Item
                  label="Message"
                  name="message"
                  rules={[{ required: true, message: 'Please fill it!' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item>
                  <AntdButton type="primary" htmlType="submit">
                    Send
                  </AntdButton>
                </Form.Item>
              </Form>
              <br />
              <span style={{ fontWeight: 'bold' }}>E-Mail</span>:{' '}
              <a href="mailto:info@appomark.com">info@appomark.com</a>
              <br />
              <br />
              <span style={{ fontWeight: 'bold' }}>Address</span>: 312 W 2nd St
              Unit #A1604 Casper, WY 82601, United States
            </Modal>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

HakkimizdaSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  buttonWrapper: PropTypes.object,
};

HakkimizdaSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  buttonWrapper: {
    mt: '15px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default HakkimizdaSection;
