import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';

import LogoImage from 'common/assets/image/saasModern/logo-footer.png';
import axios from 'axios';
import { Button as AntdButton, Form, Input, Modal, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        FOOTER_WIDGET {
          title
          menuItems {
            text
            url
          }
        }
      }
    }
  `);
  const [isOpen, setOpen] = useState(false);
  const [isOpenBizimleCalisin, setOpenBizimleCalisin] = useState(false);
  const [isOpenKullaniciSozlesmesi, setOpenKullaniciSozlesmesi] = useState(
    false
  );
  const [isOpenGizlilikPolitikasi, setOpenGizlilikPolitikasi] = useState(false);
  const [isOpenGDPR, setOpenGDPR] = useState(false);
  const onFinishContact = (values) => {
    axios
      .post('https://api.appomark.com/api/auth/send-contact', { ...values })
      .then((response) => {
        if (response.data.code === 1) {
          notification.success({
            message: 'Successfull!',
            description: 'We will contact you asap!',
            placement: 'bottomRight',
          });
          setOpen(false);
        } else {
          notification.error({
            message: 'Failed!',
            description:
              'We are currently experiencing a problem. Please try again later!',
            placement: 'bottomRight',
          });
          setOpen(false);
        }
      });
  };
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="Appomark"
              logoStyle={logoStyle}
            />
            <Text
              content="Appomark is appointment scheduling, accounting, customer management, and marketing software."
              {...textStyle}
            />
            {/*<Text*/}
            {/*  content="Adres: Adalet Mah. Manas Bulvarı Folkart Towers Kat:28 Bayraklı, İzmir"*/}
            {/*  {...textStyle}*/}
            {/*/>*/}
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.saasModernJson.FOOTER_WIDGET.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      {item.text === 'Contact Us' ? (
                        <span onClick={() => setOpen(true)}>{item.text}</span>
                      ) : item.text === 'Work With Us' ? (
                        <span onClick={() => setOpenBizimleCalisin(true)}>
                          {item.text}
                        </span>
                      ) : item.text === 'User Agreement' ? (
                        <span onClick={() => setOpenKullaniciSozlesmesi(true)}>
                          {item.text}
                        </span>
                      ) : item.text === 'Privacy Policy' ? (
                        <span onClick={() => setOpenGizlilikPolitikasi(true)}>
                          {item.text}
                        </span>
                      ) : item.text === 'GDPR' ? (
                        <span onClick={() => setOpenGDPR(true)}>
                          {item.text}
                        </span>
                      ) : (
                        item.text
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
          <Modal
            width={600}
            title="Contact Us"
            visible={isOpen}
            onCancel={() => setOpen(false)}
            footer={null}
          >
            <Form name="basic" onFinish={onFinishContact}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number!',
                  },
                ]}
              >
                <PhoneInput
                  country={'us'}
                  enableSearch={true}
                  countryCodeEditable={false}
                />
              </Form.Item>
              <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true, message: 'Please fill it!' }]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <AntdButton type="primary" htmlType="submit">
                  Send
                </AntdButton>
              </Form.Item>
            </Form>
            <br />
            <span style={{ fontWeight: 'bold' }}>E-Mail</span>:{' '}
            <a href="mailto:info@appomark.com">info@appomark.com</a>
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>Address</span>: 312 W 2nd St
            Unit #A1604 Casper, WY 82601, United States
          </Modal>
          <Modal
            width={600}
            title="Work With Us"
            visible={isOpenBizimleCalisin}
            onCancel={() => setOpenBizimleCalisin(false)}
            footer={null}
          >
            If you are a digital agency serving local businesses, contact us to
            become our partner!
            <br />
            <a href="mailto:info@appomark.com">info@appomark.com</a>
          </Modal>
          <Modal
            width={1000}
            title="User Agreement"
            visible={isOpenKullaniciSozlesmesi}
            onCancel={() => setOpenKullaniciSozlesmesi(false)}
            footer={null}
          >
            These terms and conditions are effective immediately for those
            registering accounts (as herein defined) after that date and will
            become effective January 1, 2016 for those with pre-existing
            accounts. To review the previous terms, please click here.
            <br />
            These terms and conditions (the "Terms") of this User Agreement (as
            herein defined) govern Your access to and use of Appomark's mobile
            applications that link to or reference this User Agreement and
            websites (collectively, the "Site"). By accessing, browsing,
            crawling, scraping or in any way using the Site, You agree to the
            Terms set forth in this User Agreement, the Appomark Privacy Policy
            and all other guidelines or policies referenced herein
            (collectively, the "User Agreement" ).
            <br />
            PLEASE READ THIS USER AGREEMENT CAREFULLY. YOUR USE OF THE SITE
            CONSTITUTES YOUR ACCEPTANCE OF THE TERMS OF THIS USER AGREEMENT. DO
            NOT USE THE SITE IF YOU ARE UNWILLING OR UNABLE TO BE BOUND BY THIS
            USER AGREEMENT.
            <br />
            1. DEFINITIONS
            <br />
            (a) "User" is someone who accesses, browses, crawls, scrapes or in
            any way uses the Site. The terms "You", "Your" and "Yourself" refer
            exclusively to You, as a User of the Site. The terms "We", "Us",
            "Our", and "Appomark" refer exclusively to Appomark, Inc., a
            California corporation, with offices at 4120 Dublin Blvd Suite 110,
            Dublin, CA 94568. Each of “You”, “Your”, “Yourself” and “We”, “Us”,
            “Our” and “Appomark”, is a “Party” and together the “Parties”.
            <br />
            (b) "Content" means text, images, photos, audio, video and all other
            forms of data or communication. "Your Content" means Content that
            You submit or transmit to or through the Site, such as ratings,
            reviews, compliments, invitations and information that You display
            as part of Your account profile. "User Content" means Content that
            Users submit or transmit to or through the Site. "Appomark Content"
            means Content that We create and make available on the Site. "Third
            Party Content" means Content that is made available on the Site by
            parties other than Appomark or its Users, such as data providers who
            license data to Appomark for use on the Site. "Site Content" means
            all of the Content that is made available on the Site, including
            Your Content, User Content, Third Party Content, and Appomark
            Content.
            <br />
            2. ELIGIBILITY
            <br />
            Minors. If You are a minor (under the age of 18), You can use this
            service only with the consent and under the supervision of Your
            parents or legal guardians. If You are a minor, please do not submit
            any personal information to this website.
            <br />
            IF YOU ARE 13 YEARS OR YOUNGER, PLEASE DO NOT USE THIS SITE OR ANY
            OF ITS SERVICES FOR ANY PURPOSE. YOU MUST BE AT LEAST 18 YEARS OF
            AGE TO CREATE OR USE A BUSINESS ACCOUNT (as herein defined). You
            must have the requisite power and authority to enter into this User
            Agreement in order to use this Site. If You are between the ages of
            14 to 18 years old, You must have consent and be under the
            supervision of Your parents before creating or using a Personal
            Account. You may not access or use the Site if: (1) You believe You
            are a competitor of Ours, (2) We deem You as a competitor of Ours,
            or (3) We have previously banned You from the Site. If You use the
            Site on behalf of a company, entity or organization, You represent
            that You are an authorized representative of such company, entity or
            organization with the authority to bind it to the this User
            Agreement (which authority You hereby exercise). To the extent that
            You do not meet the eligibility requirements as set forth herein,
            Appomark may, at its sole discretion, elect to terminate Your
            account.
            <br />
            3. CHANGES TO THE USER AGREEMENT
            <br />
            We may modify this User Agreement from time to time, for example, to
            reflect changes to the law or changes to the Site. You should look
            at the User Agreement regularly. We'll post notice of modifications
            to the User Agreement on this page. When changes are made, We will
            notify You by making the revised version available on the Site and
            will indicate on the Site the date on which revisions were last
            made. Changes will not apply retroactively and will become effective
            no sooner than thirty days after they are posted for pre-existing
            accounts. However, changes addressing new functions for the Site or
            changes made for legal reasons will be effective immediately. If You
            do not agree to the modified terms for this User Agreement, You
            should discontinue Your use of the Site. You should revisit this
            User Agreement on a regular basis as revised versions will be
            binding on You. Your continued use of the Site after any posted
            modification to this User Agreement indicates Your assent to the
            modified and/or restated User Agreement. 4. USER ACCOUNTS
            <br />
            To use some of the features on the Site, You may be required to
            create an account and provide information about Yourself to Us. You
            are exclusively responsible for maintaining the confidentiality of
            Your account password. You are also exclusively responsible for all
            activities that occur in connection with Your account. You agree to
            notify Us immediately of any unauthorized use of Your account. We
            reserve the right to close Your account at any time for any or no
            reason. There are two types of accounts:
            <br />
            (a) "Personal Account" is an account for Your personal,
            non-commercial use only. In creating a Personal Account, We ask that
            You provide complete and accurate information about Yourself to
            bolster Your credibility as a contributor to the Site. You may not
            impersonate or provide an email address other than Your own, or
            create multiple Personal Accounts.
            <br />
            (b) "Business Account" is an account to be used solely for the
            purpose of representing Your business on the Site. In creating or
            updating a Business Account, You must be an authorized
            representative of the business in question, and You must provide
            complete and accurate information about Yourself and the business
            You represent. You may not provide an email address that is not Your
            own or create multiple Business Accounts for the same business. Your
            Business Account accepts these terms. It will hold harmless and
            indemnify Us from any claim, suit or action arising from or related
            to the use of the Site or violation of these terms, including any
            liability or expense arising from claims, losses, damages, suits,
            judgments, litigation costs and attorneys' fees. Additional terms
            associated with a Business Account are further set forth in the
            “Appomark Customer Participation Agreement”, which, additional
            terms, applicable to all Business Accounts, are incorporated herein
            by reference.
            <br />
            5. USE OF THE SITE
            <br />
            We grant You permission to use the Site subject to the restrictions
            in this User Agreement. In using the Site, You may be exposed to
            Content that is offensive, indecent, untruthful, inaccurate,
            objectionable, defamatory or otherwise inappropriate. Appomark does
            not endorse such Content and as part and parcel of this User
            Agreement, specifically precludes all Users from submitting or
            posting such to the Site. Use of the Site is available on mobile
            devices. Do not use the Site in a way that distracts You and
            prevents You from obeying traffic or safety laws.
            <br />
            6. USE RESTRICTIONS
            <br />
            We are under no obligation to enforce the User Agreement on Your
            behalf against another User. While We encourage You to let Us know
            if You believe another User has violated the User Agreement, We
            reserve the right to investigate and take appropriate action at Our
            sole discretion.YOU AGREE NOT TO, AND WILL NOT ASSIST OR ENABLE
            OTHERS TO:
            <br />
            We are under no obligation to enforce the User Agreement on Your
            behalf against another User. While We encourage You to let Us know
            if You believe another User has violated the User Agreement, We
            reserve the right to investigate and take appropriate action at Our
            sole discretion.YOU AGREE NOT TO, AND WILL NOT ASSIST OR ENABLE
            OTHERS TO:
            <br />
            (a) use the Site to threaten, stalk, defraud, incite, harass, or
            advocate the harassment of another person, or otherwise interfere
            with another User's use of the Site;
            <br />
            (b) use the Site to submit or transmit spam, chain letters,
            contests, junk email, pyramid schemes, surveys, or other mass
            messaging, whether commercial in nature or not;
            <br />
            (c) use the Site for promotional or commercial purposes, except in
            connection with a Business Account and as expressly allowed in
            writing by Appomark;
            <br />
            (d) use the Site in a manner that may create a conflict of interest,
            such as trading reviews with other business owners, compensating
            someone or being compensated to write or remove a review, or by
            writing a fake or defamatory review;
            <br />
            (e) use the Site for keyword spamming or to otherwise attempt to
            manipulate natural search results;
            <br />
            (f) use the Site to promote bigotry or discrimination against any
            persons or other Users for any reason;
            <br />
            (g) use the Site to violate any third-party rights, including any
            breach of confidence, copyright, trademark, patent, trade secret,
            moral right, privacy right, right of publicity, or any other
            intellectual property or proprietary right of any person or entity;
            <br />
            (h) use the Site to submit or transmit any nude images of any kind,
            pornography or illegal content;
            <br />
            (i) use the Site to solicit personal information from minors or to
            harm or threaten to cause harm to minors;
            <br />
            (j) use the Site in violation of this User Agreement or any
            applicable law;
            <br />
            (k) modify, adapt, appropriate, reproduce, distribute, translate,
            create derivative works or adaptations of, publicly display, sell,
            trade, or in any way exploit the Site, or Site Content (other than
            Your Content) or other User Content, except as expressly authorized
            by Appomark;
            <br />
            (l) reverse engineer any portion of the Site, except as may be
            permitted under the law;
            <br />
            (m) remove or modify any copyright, trademark or other proprietary
            rights notice on the Site or on any materials printed or copied off
            of the Site;
            <br />
            (n) record, process or mine information about other Users;
            <br />
            (o) use any robot, spider, site search/retrieval application or
            other automated device, process or means to access, retrieve, scrape
            or index the Site or any Site Content;
            <br />
            (p) access, retrieve or index the Site to for purposes of
            constructing or populating a search-able database of business
            reviews;
            <br />
            (q) reformat or frame any portion of the Site;
            <br />
            (r) take any action that imposes, or may impose in Our sole
            discretion, an unreasonable or disproportionately large load on
            Appomark's computer systems;
            <br />
            (s) attempt to gain unauthorized access to the Site, User accounts,
            computer systems or networks connected to the Site through hacking,
            password mining or any other means; use the Site or any Site Content
            to transmit any computer viruses, worms, defects, Trojan horses or
            other items of a destructive nature (collectively, "Viruses"); use
            any device, software or routine that interferes with the proper
            working of the Site, or otherwise attempt to interfere with the
            proper working of the Site; make excessive traffic demands; use the
            Site to violate the security of any computer network, crack
            passwords or security encryption codes; disrupt or interfere with
            the security of, or otherwise cause harm to, the Site or Site
            Content; remove, circumvent, disable, damage or otherwise interfere
            with any security-related features of the Site, features that
            prevent or restrict the use or copying of Site Content, or features
            that enforce limitations on the use of the Site; and make any
            untruthful or defamatory statements.
            <br />
            7. PERMISSION TO USE YOUR CONTENT
            <br />
            Appomark will never sell its user contact information nor allow
            anyone to advertise to other businesses clients other than their
            own. For example, if you book or register with a Appomark Business
            Account, you consent to receiving communications from that Business
            Account such as marketing information or appointment reminders.
            <br />
            We may use Your Content in a number of different ways, including
            displaying it on the Site, reformatting it, incorporating it into
            other works, creating derivative works from it, promoting it,
            distributing it and allowing others to do the same in connection
            with their own websites and media platforms (collectively, "Other
            Media"). As such, You hereby irrevocably grant Us a world-wide,
            perpetual, non-exclusive, royalty-free, assignable, sub-licensable,
            transferable rights to use Your Content for any purpose. You also
            irrevocably grant the Site's Users and the Users of any Other Media
            the right to access Your Content in connection with their use of the
            Site and any Other Media. Finally, You irrevocably waive, and cause
            to be waived, against Appomark and Our Users any claims and
            assertions of moral rights or attribution with respect to Your
            Content. By “use” We mean use, copy , publicly perform and display,
            reproduce, distribute, modify, translate, remove, analyze,
            commercialize, and prepare derivative works of Your Content.
            <br />
            8. RESPONSIBILITY FOR YOUR CONTENT
            <br />
            You alone are responsible for Your Content. You assume all risks
            associated with Your Content, including, but not limited to,
            anyone's reliance on its accuracy, quality, reliability,
            completeness or usefulness, or any disclosure by You of information
            in Your Content that makes You personally identifiable. You
            represent that You own, or have the necessary permissions to use,
            and authorize the use of, Your Content as described herein. You may
            not imply that Your Content is in any way sponsored or endorsed by
            Appomark. You may expose Yourself to liability if, for example, Your
            Content violates any third-party right, including, but not limited
            to, any copyright, trademark, patent, trade secret, moral right,
            privacy right, right of publicity or any other intellectual property
            or proprietary right; contains material that is false, intentionally
            misleading, or defamatory; contains material that is unlawful,
            including illegal hate speech or pornography; exploits or otherwise
            harms minors; or violates or advocates the violation of any law or
            regulation.
            <br />
            9. USE OF CONTENT
            <br />
            We may remove or reinstate User Content from time to time at Our
            sole discretion. For example, We may remove a review if We believe
            it violates this User Agreement. We have no obligation to retain or
            provide You with copies of Your Content, nor do We make any
            guarantees or representations concerning any confidentiality with
            respect to Your Content. Appomark and its licensees may display
            advertisements and other information adjacent to or included with
            Your Content on the Site and Other Media. You are not entitled to
            any compensation for such advertisements. The manner, mode and
            extent of such advertising are subject to change without specific
            notice to You. User Content (including any that may have been
            created by Users employed or contracted by Appomark) does not
            necessarily reflect the opinion of Appomark. We reserve the right to
            remove, screen, edit, or reinstate User Content from time to time at
            Our sole discretion for any reason or no reason, and without notice
            to You. For example, We may remove a review if We believe it
            violates the User Agreement. We have no obligation to retain or
            provide You with copies of Your Content, nor do We guarantee any
            confidentiality with respect to Your Content.
            <br />
            10. APPOINTMENT POLICY
            <br />
            As part of the services offered by the Site, Appomark permits its
            Users to make appointments with certain businesses. In the event
            that a User elects to reserve an appointment with a business on the
            Site, the User covenants and agrees that he/she/it will arrive at
            the designated appointment. In the event that the User is unable to
            make the appointment after scheduling such, he/she/it agrees to
            cancel such appointment not less than twenty-four (24) hours in
            advance of the originally scheduled time. You acknowledge and agree
            that Appomark may suspend or terminate Your account in the event
            that You fail to appear at any scheduled appointment. You represent,
            covenant and agree further that You will not make any appointments
            at which You do not intend to appear and You covenant and agree
            further to refrain from any and all conduct which may be adverse to
            the interest of each of the businesses featured on the Site. Each
            User covenants and agrees to defend, indemnify and hold harmless
            Appomark from and against any and all conduct which may be adverse
            to any of the businesses soliciting appointments on the Site.
            <br />
            11. COPYRIGHT DISPUTE POLICY
            <br />
            We have adopted the following policies and procedures with regard
            and copyright infringement in accordance with Title 17, United
            States Code, Section 512(c), which is part of the Digital Millennium
            Copyright Act ("DMCA"). The address of Appomark's Designated Agent
            to Receive Notification of Claimed Infringement ("Designated Agent")
            is provided at the bottom of this section. It is Appomark&pos;s
            policy to (i) respond to notices of alleged copyright infringement
            that comply with the DMCA; and (ii) terminate the accounts of Users
            determined by Appomark to be a "repeat infringer" as referenced in
            the DMCA. We reserve the right to remove content alleged to be
            infringing without prior notice and at Our sole discretion.
            <br />
            (a) Procedure for Reporting Copyright Infringements. If You are a
            copyright owner or agent thereof, and believe that Your copyright is
            being infringed in connection with the Site, please send a written
            notification to the Designated Agent detailing the alleged
            infringement. Your written notification must include:
            <br />
            (i) Identification of the copyrighted work that You claim has been
            infringed;
            <br />
            (ii)Identification of the Content on the Site that allegedly
            infringes upon the copyrighted work at issue, and information
            reasonably sufficient to permit Appomark to locate such Content;
            <br />
            (iii) A statement by You that You have a good faith belief that the
            use of the Content identified in Your notice in the manner
            complained of is not authorized by the copyright owner, its agent,
            or the law;
            <br />
            (iv) A statement by You that You attest, under penalty of perjury,
            that the information in Your notice is accurate and that You are the
            copyright owner or authorized to act on the copyright owner's
            behalf; and
            <br />
            (v) Your physical or electronic signature, together with Your
            contact information (address, telephone number and, if available,
            email address).Please note that Appomark may, at its sole
            discretion, send a copy of such notices to third parties for
            publication. Please note that You may be subject to liability under
            Section 512(f) of the DMCA if You materially misrepresent that
            content on the Site infringes Your copyright.
            <br />
            (b) Procedure to Supply a Counter-Notice to the Designated Agent. If
            You believe that Content has been mistakenly removed from the Site
            pursuant to this Copyright Dispute Policy, You may send a written
            counter-notice to the Designated Agent. Your written counter-notice
            must include:
            <br />
            (i) Identification of the copyrighted Content that was removed, and
            the location on the Site where it would have been found prior to its
            removal;
            <br />
            (ii) A statement under penalty of perjury that You have a good faith
            belief that the Content was removed as a result of a mistake or
            misidentification;
            <br />
            (iii) A statement that You consent to the jurisdiction of the
            Federal District Court for the judicial district in which Your
            address is located, or if Your address is located outside the United
            States, for any judicial district in which Appomark is located, and
            that You will accept service of process from the person who sent the
            original infringement notice to Appomark, or an agent of such
            person;
            <br />
            (iv)Your physical or electronic signature, together with Your
            contact information (address, telephone number and, if available,
            email address).
            <br />
            (c) Address for Designated Agent. Please contact the Designated
            Agent at the following address: Appomark, Inc. Attn: Customer
            Support 4120 Dublin Blvd Suite 110 Dublin, CA 945687 Phone:
            8009190157
            <br />
            12. SITE AVAILABILITY
            <br />
            We reserve the right to modify, update, or discontinue the Site at
            Our sole discretion, at any time, for any or no reason, and without
            notice or liability.
            <br />
            13. UNAUTHORIZED ACCESS
            <br />
            We reserve the right to exercise whatever lawful means We deem
            necessary in order to prevent unauthorized access to or use of the
            Site, including, but not limited to, technological barriers, IP
            mapping and contacting Your Internet Service Provider (ISP)
            regarding such unauthorized use.
            <br />
            14. INVESTIGATIONS
            <br />
            We are under no obligation to monitor the Site or Site Content.
            However, We reserve the right to investigate possible violations of
            the User Agreement, block Users from the Site, and refer matters to
            law enforcement authorities for further investigation. We may
            disclose information to third parties, including Your Content, in
            accordance with the Privacy Policy.
            <br />
            15. TERMINATION
            <br />
            We may terminate or suspend Your account or ability to use the Site,
            in whole or in part, at Our sole discretion, for any or no reason,
            and without notice or liability of any kind. For example, We may
            terminate or suspend Your account or ability to use the Site if You
            breach this User Agreement or are suspected of involvement in
            illegal activity. Any such termination or suspension could prevent
            You from accessing Your account, the Site, Your Content, Site
            Content or any other related information. You may terminate this
            User Agreement at any time by closing Your account, discontinuing
            Your use of any and all parts of the Site. If You close Your
            account, We will use commercially reasonable efforts to stop
            displaying Your account profile on the Site, but may continue to
            display Your Content. In the event of any termination, this User
            Agreement will continue in full force and effect, including Our
            right to use Your Content.
            <br />
            We own the Appomark Content, including but not limited to visual
            interfaces, interactive features, graphics, design, compilation,
            computer code, products, software, aggregate User review ratings and
            all other elements and components of the Site excluding User Content
            and Third Party Content. We also own the copyrights, trademarks,
            service marks, trade names and other intellectual and proprietary
            rights throughout the world (the “IP Rights”) associated with the
            Appomark Content and the Site, which are protected by copyright,
            trade dress, patent, trademark laws and all other applicable
            intellectual and proprietary rights and laws. As such, You may not
            modify, reproduce, distribute, create derivative works or
            adaptations of, publicly display or in any way exploit any of the
            Appomark Content in whole or in part except as expressly authorized
            by Us. Except as expressly and unambiguously provided herein, We do
            not grant You any express or implied rights, and all rights in and
            to the Site and the Appomark Content are retained by Us.
            <br />
            17. WARRANTIES, DISCLAIMERS, AND LIMITATIONS OF LIABILITY
            <br />
            THE SITE AND ALL SITE CONTENT IS MADE AVAILABLE TO YOU ON AN “AS IS”
            BASIS. Appomark MAKES NO WARRANTIES, REPRESENTATIONS OR CONDITIONS
            OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED AS TO (1) THE OPERATION
            AND FUNCTIONALITY OF THE SITE, (2) THE ACCURACY, INTEGRITY,
            COMPLETENESS, QUALITY, LEGALITY, USEFULNESS, SAFETY AND IP RIGHTS OF
            ANY OF THE SITE CONTENT, INCLUDING BUT NOT LIMITED TO THE ACCURACY
            OF BUSINESS REVIEWS AND BUSINESS INFORMATION LISTED ON THE SITE
            AND/OR (3) THE PRODUCTS AND SERVICES ASSOCIATED WITH THE SITE OR
            SITE CONTENT, INCLUDING BUT NOT LIMITED TO THE PRODUCTS AND SERVICES
            SOLD BY BUSINESSES LISTED ON THE SITE. Appomark FURTHER DISCLAIMS
            ALL WARRANTIES, EXPRESS, STATUTORY OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, MERCHANTABLE
            QUALITY, DURABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR
            NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
            THAT YOU OBTAIN FROM Appomark OR THE SITE SHALL CREATE ANY WARRANTY,
            REPRESENTATION OR CONDITION NOT EXPRESSLY STATED HEREIN. Appomark
            DISCLAIMS ALL LIABILITY FOR ANY (A) INDIRECT, SPECIAL, INCIDENTAL,
            PUNITIVE, EXEMPLARY, RELIANCE OR CONSEQUENTIAL DAMAGES, (B) LOSS OF
            PROFITS, (C) BUSINESS INTERRUPTION, (D) LOSS OF OR DAMAGE TO
            REPUTATION OF Appomark OR ANY THIRD PARTY, OR (E) LOSS OF
            INFORMATION OR DATA. Appomark FURTHER DISCLAIMS ALL LIABILITY FOR
            ANY LOSS OR DAMAGE ARISING OUT OF YOUR USE OF THE SITE AND SITE
            CONTENT. YOUR USE OF THE SITE AND SITE CONTENT IS AT YOUR OWN
            DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            RESULTING LOSS OR DAMAGE, INCLUDING BUT NOT LIMITED TO, ANY LOSS OF
            DATA OR DAMAGE TO YOUR COMPUTER(S) OR NETWORKS FROM VIRUSES THAT MAY
            BE DOWNLOADED TO YOUR COMPUTER IN THE COURSE OF USING THE SITE.
            Appomark ALSO DISCLAIMS ANY AND ALL LIABILITY FOR ANY LOSS OR DAMAGE
            ARISING OUT OF YOUR COMMUNICATIONS OR DEALINGS WITH ANY OF THE
            BUSINESSES, ADVERTISERS OR USERS ON THE SITE. YOUR COMMUNICATIONS OR
            DEALINGS WITH SUCH BUSINESSES, ADVERTISERS AND USERS ARE SOLELY
            BETWEEN YOU AND THEM, THOUGH Appomark RESERVES THE RIGHT TO MONITOR
            DISPUTES BETWEEN YOU AND THEM. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF CERTAIN WARRANTIES, THE LIMITATION OR EXCLUSION OF
            IMPLIED WARRANTIES, OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY
            MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IF YOU
            RESIDE IN SUCH A JURISDICTION, THE ABOVE LIMITATIONS SHALL APPLY TO
            YOU TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW.
            <br />
            Appomark'S MAXIMUM AGGREGATE LIABILITY TO YOU FOR ANY CLAIMS,
            DAMAGES, INJURIES OR CAUSES WHATSOEVER, AND REGARDLESS OF THE FORM
            OF ACTION (WHETHER SUCH LIABILITY ARISES DUE TO NEGLIGENCE, BREACH
            OF CONTRACT, MISREPRESENTATION OR FOR ANY OTHER REASON), WILL AT ALL
            TIMES BE LIMITED TO THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY
            YOU TO Appomark IN CONNECTION WITH THE SITE IN THE 12 MONTHS PRIOR
            TO THE ACTION GIVING RISE TO LIABILITY OR (II) US $1.00.
            <br />
            18. INDEMNITY
            <br />
            You agree to defend, indemnify and hold harmless Appomark, its
            members, managers, subsidiaries, affiliates, any related companies,
            suppliers, licensors and partners, and the officers, directors,
            employees, agents and representatives of each of them, including
            costs, liabilities and legal fees, from any claim or demand made by
            any third party due to or arising out of: (i) Your access to or use
            of the Site, (ii) Your violation of this User Agreement, or (iii)
            the infringement by You, or any third party using Your account, of
            any intellectual property or other right of any person or entity.
            Appomark reserves the right, at Your expense, to assume the
            exclusive defense and control of any matter for which You are
            required to indemnify Us and You agree to cooperate with Our defense
            of these claims. You agree not to settle any matter without the
            prior written consent of Appomark. Appomark will use reasonable
            efforts to notify You of any such claim, action or proceeding upon
            becoming aware of it.
            <br />
            19. THIRD PARTIES
            <br />
            The Site may include links to other websites (each, a “Third Party
            Site”). We do not control or endorse any Third Party Site, and You
            agree that We are not responsible for the availability or contents
            of such Third Party Sites. Your use of a Third Party Site is at Your
            own risk. You may not distribute, sell, rent, sublicense or lease
            such Third Party Content, in whole or in part, to any third party;
            and You may not use it for any other purpose other than Your
            personal, non-commercial use.
            <br />
            20. CHOICE OF LAW AND VENUE
            <br />
            If there is any dispute about or involving the Site or Appomark, You
            agree that any such dispute will be exclusively governed by the laws
            of the State of California without regard to its conflict of law
            provisions. You agree to the personal and exclusive jurisdiction in,
            and the exclusive venue of, the state and federal courts in and for
            Alameda County, California. The Parties do not intent for this
            provision to negate or limit any provision of this User Agreement,
            nor of any other agreement between the Parties, that requires (1)
            mediation, arbitration or other non-judicial dispute resolution
            procedure; nor (2) non-binding action to attempt to resolve a
            dispute by agreement, such as (for example) escalation of the
            dispute to higher levels of the Parties' managements; early neutral
            evaluation; negotiation; and/or remediation.
            <br />
            21. MEDIATION - ARBITRATION
            <br />
            If any dispute, controversy or claim arising out of or relating to
            the Site, Appomark or this User Agreement or the breach,
            termination, non-renewal of this User Agreement or the validity of
            this User Agreement cannot be settled through negotiation, the
            Parties agree first to try in good faith to settle the dispute by
            mediation administered by the American Arbitration Association under
            its Commercial Mediation Procedures before resorting to arbitration,
            litigation, or some other dispute resolution procedure.
            <br />
            If mediation fails to settle the dispute, the Parties agree the
            dispute shall be finally settled by arbitration administered by the
            American Arbitration Association in accordance with its Commercial
            Arbitration Rules, and judgment on the award rendered by the
            arbitrator(s) may be entered in any court having jurisdiction
            thereof.
            <br />
            THE PARTIES HEREBY KNOWINGLY AND VOLUNTARILY AND IRREVOCABLY WAIVE
            THEIR RIGHT TO A TRIAL BY JURY and agree that if the foregoing
            binding arbitration provision is determined for any reason to be
            unenforceable or inapplicable to a particular dispute, then such
            dispute shall be decided solely by a judge, without the use of a
            jury, sitting in a court of competent jurisdiction. In any action to
            enforce any right or remedy under this User Agreement or to
            interpret any provision of this User Agreement, the prevailing Party
            shall be entitled to recover its costs, including reasonable
            attorneys' fees.
            <br />
            22. FORCE MAJEURE
            <br />
            In the event either party is unable to perform its obligations or
            current modifications of obligations under the terms of this
            Agreement because of acts of God, National Emergency, or other
            causes reasonably beyond its control, such party shall not be liable
            for damages to the other for any such failure to perform or
            otherwise from such causes.
            <br />
            23. MISCELLANEOUS
            <br />
            (a) No agency, partnership, joint venture or employment is created
            as a result of this User Agreement, and You do not have any
            authority of any kind to bind Us in any respect whatsoever.
            <br />
            (b) We may provide You with notices, including those regarding
            changes to this User Agreement by email, regular mail or postings on
            the Site, in Our sole discretion.
            <br />
            (c) Except as otherwise stated nothing herein is intended, nor will
            be deemed, to confer rights or remedies upon any third party.
            <br />
            (d) This User Agreement contains the entire agreement between You
            and Us regarding the use of the Site, and supersedes any prior
            agreement between You and Us on such subject matter. The Parties
            acknowledge that no reliance is placed on any representation made
            but not expressly contained in this User Agreement.
            <br />
            (e) Any failure on Our part to exercise or enforce any right or
            provision of this User Agreement shall not constitute a waiver of
            such right or provision. The failure of either Party to exercise in
            any respect any right provided for herein shall not be deemed a
            waiver of any further rights hereunder.
            <br />
            (f) If any provision of this User Agreement is found to be
            unenforceable or invalid under any applicable law or be so held by
            applicable court decision, such unenforceability or invalidity shall
            not render this User Agreement unenforceable or invalid as a whole.
            In such event, such provision shall be changed and interpreted so as
            to best accomplish the objectives of such provision within the
            limits of applicable law or applicable court decision to the minimum
            extent necessary so that this User Agreement shall otherwise remain
            in full force and effect and enforceable.
            <br />
            (g) This User Agreement is not assignable, transferable or
            sublicensable by You, except with Appomark's prior written consent,
            but may be assigned or transferred by Us without restriction. Any
            assignment attempted to be made by You in violation of this User
            Agreement shall be void. (h) The section titles in this User
            Agreement are for convenience only and have no legal or contractual
            effect.
          </Modal>
          <Modal
            width={1000}
            title="Privacy Policy"
            visible={isOpenGizlilikPolitikasi}
            onCancel={() => setOpenGizlilikPolitikasi(false)}
            footer={null}
          >
            This Privacy Notice describes how Appomark Inc. and our affiliates
            (collectively, “Appomark ,” “we,” and “us”) collect, use, disclose,
            transfer, store, retain or otherwise process your information when
            you (whether you are a person acting as a sole proprietor or on
            behalf of another business entity) apply or sign up for a Appomark
            account and other services through Appomark's website or
            applications (collectively, “Services”).
            <br />
            <br />
            This Privacy Notice applies to information collected in connection
            with your access to and use of our Services. Please read this
            Privacy Notice carefully.
            <br />
            <br />
            By continuing to interact and use our Services directly or
            indirectly, you are consenting to the practices, policies described
            in this Privacy Notice.
            <br />
            <br />
            Our Privacy Notice explains:
            <br />
            <br />
            Information we collect about you Information we may collect about
            your customers Sources of information we collect about you How we
            use your information When and with whom we share your information
            Cookies and other similar automated technologies Third-Party
            analytics services Online tracking and interested based advertising
            How long we keep your information Your choices Online behavioral or
            interest base advertising Rights of California Residents Rights of
            EU Residents Children's personal information Storage and processing
            Changes to this Privacy Notice How to contact us INFORMATION WE
            COLLECT ABOUT YOU
            <br />
            We use your data to develop and insure Appomark'vs products and
            services work better for you and for others. This describes what
            data we collect about you (which can vary depending on
            jurisdiction).
            <br />
            <br />
            INFORMATION YOU PROVIDE / PERMISSION TO USE YOUR CONTENT
            <br />
            Appomark uses data you input into our system when you open, register
            and use a Appomark account. Examples include but are not limited to;
            email addresses or phone numbers, your contact list on your
            singed-in device(s) if you request an import, information that
            identifies who you are, transaction information and any other data
            you give us. Appomark will never sell its user content/contact
            information nor allow anyone to advertise to any businesses/users
            other than those you have provided explicit consent. For example, if
            you book or register with a Appomark Business Account, you consent
            to receiving communications from that Business Account such as
            marketing information or appointment reminders. We may use Your
            Content in a number of different ways, including displaying it on
            the Site, reformatting it, incorporating it into other works,
            creating derivative works from it, promoting it, distributing it and
            allowing others to do the same in connection with their own websites
            and media platforms (collectively, "Other Media"). As such, You
            hereby grant Us a world-wide, perpetual, non-exclusive,
            royalty-free, assignable, sub-licensable, transferable rights to use
            Your Content for any purpose.
            <br />
            <br />
            "Content" means text, images, photos, audio, video and all other
            forms of data or communication. "Your Content" means Content that
            You submit or transmit to or through the Site, such as ratings,
            reviews, compliments, invitations, and information that You display
            as part of Your account profile. "User Content" means Content that
            Users submit or transmit to or through the Site.
            <br />
            <br />
            Please review the User Agreement at the footer section.
            <br />
            <br />
            We collect information you provide when you:
            <br />
            <br />
            Make a request to receive information about Appomark or our
            services/products. Apply or sign up for a Appomark account or other
            Services. Go through our identity or account verification process,
            authenticate into your account, communicate with us, answer our
            surveys, upload content, or otherwise interact with the Services.
            Specifically, we collect (and/or have collected during at least the
            12-month period preceding the effective date of this Privacy Notice)
            the following categories of information:
            <br />
            <br />
            Financial information, such as bank account information and payment
            card numbers; Transaction information, such as information about
            when and where payment transactions occur, the names of the
            transacting parties, a description of the transactions, the payment
            or transfer amounts, billing and shipping information, and the
            devices and payment methods used to complete the transactions. Other
            Information You Provide, such as information that you voluntarily
            provide to us, including images you upload to the Appomark Services,
            your survey responses; participation in contests, promotions, or
            other prospective seller marketing forms or devices; suggestions for
            improvements; referrals; or any other actions you perform on the
            Services. Internet or other electronic network activity information,
            which includes information about how you use and interact with our
            Services, including your access time, “log-in” and “log-out”
            information, browser type and language, the domain name of your
            internet service provider, other attributes about your browser, any
            specific page you visit on our platform, content you view, features
            you use, the date and time of your use of the Services, your search
            terms, and the website you visited before you visited or used the
            Services; Online identifiers (e.g., information you use to log in to
            your account), Internet Protocol (“IP”) address, and unique personal
            identifiers (including device identifier; cookies, beacons, pixel
            tags, mobile ad identifiers and similar technology; customer number;
            unique alias, and other identifiers); Professional or
            employment-related information, such as information you provide
            about your business (e.g., appointments, staffing availability, and
            contact data) and your employees (e.g., job titles, payroll
            information, and hours worked and other timecard data); Your Contact
            List from your signed-in device(s), imported into your Appomark
            account via the AppomarkPro App upon your request and consent;
            Inferences drawn from any of the information above to create a
            profile about you that may reflect, for example, your preferences,
            characteristics, and behavior, including for account security
            purposes or to enhance our Services to you. Identification
            Information, such as: Name, email address, postal address,
            signature, and phone number; FEIN, driver's license number, Social
            Security number, Taxpayer Identification number, or other
            government-issued identification number; INFORMATION WE MAY COLLECT
            ABOUT YOUR CUSTOMERS
            <br />
            We also obtain information about your customers on your behalf when
            they transact with you. We call this information Your Customers'
            Data. We collect Your Customers' Data when they transact with you
            through your use of Appomark's products, for instance when they make
            a payment at your establishment, or schedule an appointment, or
            receive an invoice from you. The Customer Data we collect will vary
            depending on how you use our products and services and which
            products and services you use. Your Customers' Data may include:
            <br />
            <br />
            Device Information. Information about Your Customer's device,
            including hardware model, operating system and version, device name,
            unique device identifier, mobile network information, and
            information about the device's interaction with our Services.
            Financial Information. Bank account and payment card numbers.
            Identification Information. Your Customers' name; email address;
            mailing address; phone number; government-issued identification; or
            other historical, contact, and demographic information, and
            signature. Location Information. The location of Your Customer's
            device depending on how they pay. Transaction Information. When Your
            Customers use Appomark to make or record payments to you, we collect
            information about when and where the transactions occur, the names
            of the transacting parties, a description of the transactions which
            may include item-level data, the payment or transfer amounts,
            billing and shipping information, and the devices and payment
            methods used to complete the transactions. Use Information.
            Information about how Your Customers transact with you using our
            Services, including access time, “log-in” and “log-out” information,
            browser type and language, country and language setting on your
            device, IP address, the domain name of Your Customer's Internet
            service provider, other attributes about Your Customer's browser,
            mobile device and operating system, features Your Customer uses, and
            the date and time of use of the Services. Other Information You or
            Your Customers Provide. Information that Your Customers voluntarily
            provide you, or that you input into Appomark's systems about your
            Customers. For example, survey responses; participation in contests,
            promotions, or other prospective seller marketing forms or devices;
            suggestions for improvements; notes you take about your customers,
            or any other actions performed when they transact with you using
            Appomark. INFORMATION YOUR CUSTOMERS MAY PROVIDE Appomark
            <br />
            Identification information. Your customers' name, email address, or
            telephone number, which we link to a tokenized version of their
            payment card number. Privacy laws that apply in certain places, like
            California, treat “businesses” and “service providers” differently.
            Under those laws, a business is the company that decides why and how
            to process personal information. A service provider processes
            personal information on behalf of a business in order to provide
            services. When Appomark processes Your Customers' Data, we generally
            act as your service provider. In select cases, however, we may act
            as a business when we process Your Customers' Data. For example, we
            act as a business when we use Your Customers' Data to send Your
            Customers digital receipts directly from Appomark, or when we allow
            you to use Customer Directory or Appomark Marketing to contact your
            buyer using a masked email address that gets routed to them via
            Appomark. SOURCES OF INFORMATION WE COLLECT ABOUT YOU Provided by
            you and the sources listed below
            <br />
            <br />
            We collect (and/or have collected during at least the 12-month
            period preceding the effective date of this Privacy Notice)
            information about you from the following categories of sources:
            <br />
            <br />
            You directly, when you submit information to us or allow us to
            access information about you; Your devices and applications when you
            interact with our website or use our Services; Our group companies
            and affiliates; Other sources, including: Social media networks;
            Online advertising companies; Service providers who help us with
            third-party identity verification, credit confirmation and fraud
            detection; Credit bureaus and financial institutions; Contact List
            from signed-in device(s); Mailing list providers; and Publicly
            available sources (such as public records of criminal convictions
            and arrest records). HOW WE USE YOUR INFORMATION
            <br />
            We use your data to do things like make sure the product offerings
            work, help you log in to your account, verify who you are, secure
            your data, fight fraud, follow the law, enforce our agreements,
            figure out what new products we can develop, and market products to
            you that we think could make it easier for you to run your business.
            <br />
            <br />
            We may collect, use and share (or have collected, used or shared
            during at least the 12-month period preceding the effective date of
            this Privacy Notice), information about you for the following
            reasons:
            <br />
            <br />
            Performing, improving and developing our services
            <br />
            <br />
            Delivering the information and support you request, including
            technical notices, security alerts, and support and administrative
            messages such as to resolve disputes, collect fees, and provide
            assistance for problems with our Services or your Appomark account;
            Determining what or whether the Services are available in your
            country; Developing new products and services; Displaying your
            historical transaction or appointment information; Improving,
            personalizing, and facilitating your use of our Services; Processing
            or recording payment transactions or money transfers; Providing,
            maintaining and improving our Services, including our website; and
            Otherwise providing you with the Appomark products and features you
            choose to use. COMMUNICATING WITH YOU ABOUT OUR SERVICES
            <br />
            Sending you surveys and getting your feedback about our Services.
            Providing information about and promoting our Services and
            third-party services to you. Sending you information we think you
            may find useful or which you have requested from us about our
            products and services. PROTECTING OUR SERVICES AND MAINTAINING A
            TRUSTED ENVIRONMENT
            <br />
            Conducting investigations, complying with and enforcing applicable
            laws, regulations, legal requirements and industry standards, and
            responding to lawful requests for information from the government or
            to valid legal process; Contacting you to resolve disputes, collect
            fees, and help you with our Services; Debugging to identify and fix
            errors that impair how our Services function; Making sure you follow
            our USER PARTICIPATION AGREEMENT or applicable agreements or
            policies; Investigating, detecting, preventing, recovering from or
            reporting fraud, misrepresentations, security breaches or incidents,
            other potentially prohibited, malicious, or illegal activities, or
            to otherwise help protect your account; Protecting your, our, our
            customers', or your customers' rights or property, or the security
            or integrity of our Services; Verifying or maintaining the quality
            and safety of our Services; and Verifying your identity ADVERTISING
            AND MARKETING
            <br />
            Marketing our Services to you; and Communicating with you about
            opportunities, products, services, contests, promotions, discounts,
            incentives, surveys, and rewards offered by us and select partners.
            If we send you marketing emails, each email will have instructions
            on how you can “opt out” of getting future marketing from us. WHEN
            AND WITH WHOM WE SHARE YOUR INFORMATION
            <br />
            We may share the personal information described “Information we
            collect about you” section with the following categories of service
            providers and third parties:
            <br />
            <br />
            WITH OTHER USERS OF OUR SERVICES WITH WHOM YOU INTERACT
            <br />
            With other users of our Services with whom you interact through your
            own use of our Services. For example, we may share information with
            your customers when you make or accept a payment, appointment, or
            money transfer using our Services. WITH OUR AFFILIATES AND GROUP
            COMPANIES
            <br />
            With our group companies and corporate affiliates, for the reasons
            outlined above. For example, we may share your information
            internally to understand how you engage with Appomark company
            products to help make our Services better for you and for everyone,
            and to help us build Services tailored to your preferences. WITH OUR
            SERVICE PROVIDERS
            <br />
            With service providers who help us provide, maintain, and improve
            our Services (e.g., vendors who help us with fraud prevention,
            identity verification, and fee collection services), as well as
            financial institutions, payment networks, payment card associations,
            credit bureaus, partners and other entities that help us provide the
            Services; With service providers that help us run our advertising
            campaigns, contests, special offers, or other events or activities.
            WITH YOUR CONSENT
            <br />
            With your consent. For example: At your direction or as described at
            the time you agree to share; or When you authorize a third-party
            application or website to access your information. AGGREGATED AND
            ANONYMIZED INFORMATION
            <br />
            We also may share (within our group of companies or affiliates, or
            with service providers or other third parties) aggregated and
            anonymized information that does not specifically identify you or
            any individual user of our Services. COOKIES AND OTHER SIMILAR
            TECHNOLOGIES
            <br />
            Digital cookies and similar technologies help us make our services
            better to use by doing things like recognizing when you've signed
            in, analyzing how you use our services so we can make them more
            useful to you, giving you a more personalized experience, and making
            our ads to you work better.
            <br />
            <br />
            We use these automated technologies to collect your device
            information, internet activity information, and inferences as
            described above. These technologies help us to:
            <br />
            <br />
            Remember your information so you do not have to re-enter it; Track
            and understand how you use and interact with our online services and
            emails; Tailor our online services to your preferences; Measure how
            useful and effective our services and communications are to you; and
            Otherwise manage and enhance our products and services. THIRD-PARTY
            ADVERTISING AND ANALYTICS
            <br />
            We use other companies as service providers to help us analyze our
            site, track metrics, and advertise to you. These service providers
            generally promised us under contract to keep data private but have
            their own policies that you should be aware of:
            <br />
            <br />
            We may use third-party analytics service providers to help us with
            our online services, such as but not limited to Google Analytics or
            Facebook. The analytics providers that administer these services use
            technologies such as cookies, web beacons, and web server logs to
            help us analyze how you use our online services.
            <br />
            <br />
            To learn more about how Google Analytics use your date please visit
            https://marketingplatform.google.com/about/  To learn more about how
            Facebook uses your data please visit
            https://www.facebook.com/help/325807937506242/ or log on to your
            Facebook account and access your settings. To understand more about
            Facebook advertising please see here
            https://www.facebook.com/about/ads. HOW LONG WE KEEP YOUR
            INFORMATION
            <br />
            We keep your information as long as you keep using our products and
            services. After that, we keep it for as long as we need it to insure
            business necessities and continue without interruption.
            <br />
            <br />
            We generally keep your information as long as reasonably necessary
            to provide you the Services or to comply with applicable law.
            However, even after you deactivate your account, we can retain
            copies of information about you and any transactions or Services in
            which you may have participated for a period of time that is
            consistent with applicable law, applicable statute of limitations or
            as we believe is reasonably necessary to comply with applicable law,
            regulation, legal process, or governmental request, to detect or
            prevent fraud, to collect fees owed, to resolve disputes, to address
            problems with various stakeholders.
            <br />
            <br />
            LOCATION INFORMATION
            <br />
            In order to provide certain Services, we may request access to
            location information, including precise geolocation information
            collected from your device. If you do not consent to the collection
            of this information, certain Services will not function properly,
            and you will not be able to use those Services. You can stop our
            collection of location information at any time by changing the
            preferences on your mobile device. If you do so, some of our mobile
            applications will no longer function. You also may stop our
            collection of location information via mobile application by
            following the standard uninstall process to remove all Appomark
            mobile applications from your device.
            <br />
            <br />
            PROMOTIONAL COMMUNICATION
            <br />
            You can opt out of receiving promotional email messages from
            Appomark by either following the opt-out instructions included in
            those messages or by logging into your Appomark account and changing
            your email notification settings. You may only opt out of
            promotional text messages from Appomark by replying STOP.
            <br />
            <br />
            Opting out of receiving communications may impact your use of the
            Services. If you decide to opt out, we may still send you
            non-promotional (transactional) communications, such as digital
            receipts and messages about your account or our ongoing business
            relations.
            <br />
            <br />
            RIGHTS OF CALIFORNIA RESIDENTS
            <br />
            California Civil Code Section 1798.83, also known as the “Shine The
            Light” law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
            <br />
            <br />
            If you are under 18 years of age, reside in California, and have a
            registered account with the Site or our mobile application, you have
            the right to request removal of unwanted data that you publicly post
            on the Site or our mobile application. To request removal of such
            data, please contact us using the contact information provided
            below, and include the email address associated with your account
            and a statement that you reside in California. We will make sure the
            data is not publicly displayed on the Site or our mobile
            application, but please be aware that the data may not be completely
            or comprehensively removed from our systems.
            <br />
            <br />
            Businesses that use Appomark software own and operate their own
            business entity and may do certain things differently than us.
            Appomark business customers and Appomark are independent third
            parties, and this Privacy Policy does not apply to the privacy
            practices of any independent third-party business user of Appomark
            services. Certain laws require businesses meet specific criteria and
            thresholds before rights are recognized and enforced. Consumers
            should review specific policies of all businesses they patron to
            determine their specific rights under the context to which it
            applies.
            <br />
            <br />
            PRIVACY RIGHTS REGARDING YOUR PERSONAL INFORMATION
            <br />
            This section provides specific information for California residents,
            as required under California privacy laws, including the California
            Consumer Privacy Act (“CCPA”) as well as other jurisdictions and
            regulations that allow for individual privacy rights such as the
            European Economic Area, including the United Kingdom, and the
            General Data Protection Regulation (“GDPR”).
            <br />
            <br />
            EXPLANATION OF INDIVIDUAL RIGHTS
            <br />
            Right to a Copy/Access or Portability:You may have the right to
            request, free of charge, a copy of the specific pieces of Personal
            Information that we have collected about you in a readily useable
            format that allows you to transmit this information to another
            entity without hindrance.
            <br />
            <br />
            Right to Know:You may have the right to request, free of charge,
            that we provide certain information about how we have handled your
            Personal Information, including the categories of Personal
            Information collected; categories of sources of Personal
            Information; business and/or commercial purposes for collecting your
            Personal Information; categories of third parties/with whom we have
            shared your Personal Information; and whether we sell any categories
            of Personal Information to third parties (however, we do not sell
            your Personal Information).
            <br />
            <br />
            Right to Deletion:You may have the right to request deletion of your
            Personal Information that we have collected, subject to certain
            exemptions. Please note that we may need to retain certain
            information for record-keeping purposes and/or to complete any
            transactions that you began prior to requesting a change or deletion
            (e.g., when you make a purchase or enter a promotion, you may not be
            able to change or delete the Personal Information provided until
            after the completion of such purchase or promotion). We may also
            retain residual information, such as records to document that your
            request has been fulfilled.
            <br />
            <br />
            Right to Non-Discrimination:You may have the right not to receive
            discriminatory treatment on the basis of exercising your privacy
            rights under applicable law.
            <br />
            <br />
            Right to Correct/Rectify: You may have the right to rectify any
            incorrect Personal Information we may hold about you.
            <br />
            <br />
            Right to Object/Restrict: You may have the right to object to a
            specific use of your Personal Information as it is laid out in this
            Privacy Policy subject to our legitimate business interests.
            <br />
            <br />
            SUBMITTING A REQUEST
            <br />
            Where applicable law allows for such a right, if you would like to
            request to access, correct, object to the use, restrict or delete
            Personal Information that you have previously provided to us, or if
            you would like to request to receive an electronic copy of your
            Personal Information for purposes of transmitting it to another
            company (to the extent this right to data portability is provided to
            you by applicable law), you may submit a request through the
            Appomark Services themselves or contact us at info@appomark.com
            <br />
            <br />
            with a subject line "Data Subject Request". We will respond to your request consistent with
            applicable law.
            <br />
            <br />
            If you are an End User you may, depending on the Appomark Services
            utilized, be able to access, correct or request deletion of Personal
            Information that you have previously provided to us through your
            online customer account. These Data Subject Requests and other
            rights, including objection, restriction and portability (to the
            extent this right to data portability is provided to you by
            applicable law), can also be made directly to the relevant
            Subscriber.
            <br />
            <br />
            For your protection, we may only implement requests with respect to
            the Personal Information associated with the particular email
            address that you use to send us upon registering for a Appomark
            account, and we may need to verify your identity before implementing
            your request. Where applicable law allows for an authorized agent to
            submit such a request, please contact us at info@appomark.com with a
            subject line "Data Subject Request - Agent Request" and someone will
            be in touch with the agent and the End User to verify the request.
            We will try to comply with your request as soon as reasonably
            practicable and meet statutory time lines. Moreover, where you are
            an End User, Appomark may need to forward your request and refer you
            to your account with Appomark Customer(s) who may be better placed
            to address your request.
            <br />
            <br />
            If you are under 18 years of age and a user of the Appomark Services
            under a verifiable Appomark account or can otherwise be identified,
            you may also be entitled to ask us to remove content or information
            that has been created as part of Appomark Service(s) by submitting a
            request to info@appomark.com.
            <br />
            <br />
            If you are an End User of one of our Appomark Business Customer(s)
            and would no longer like to be contacted by one of our Appomark
            Business Customer(s) or would like to request and exercise any of
            the rights as set out above in relation to Personal Information held
            by Appomark Business Customer(s), please contact them directly.
            <br />
            <br />
            CHILDREN'S PERSONAL INFORMATION
            <br />
            If you are under 13, please don't use our services. If we collect
            your data and later learn you are under 13, we will immediately
            delete it.
            <br />
            <br />
            SECURITY
            <br />
            We take security of your data seriously and use best practices to
            keep your data safe. No business can ever guarantee hackers won't be
            able to break into our site(s) however security and protection of
            your data is given the highest priority.
            <br />
            <br />
            We take reasonable measures, including administrative, technical,
            and physical safeguards, to protect your information from loss,
            theft, and misuse, and unauthorized access, disclosure, alteration,
            and destruction. Nevertheless, the internet is not a 100% secure
            environment, and we cannot guarantee absolute security of the
            transmission or storage of your information. We hold information
            about you both at our own premises and with the assistance of
            third-party service providers.
            <br />
            <br />
            For more information about our security practices, please contact
            info@appomark.com.
            <br />
            <br />
            STORAGE AND PROCESSING
            <br />
            We operate in several countries and we (or our service providers)
            may move your data and process it various geographical locations.
            <br />
            <br />
            We may, and we may use third-party service providers to, process and
            store your information in the United States, Canada, the European
            Union, and other countries.
            <br />
            <br />
            CHANGES TO THIS PRIVACY NOTICE
            <br />
            We may amend this Privacy Notice from time to time by posting a
            revised version and updating the “Effective Date” above. The revised
            version will be effective on the “Effective Date” listed. We will
            provide you with reasonable prior notice of material changes in how
            we use your information, including by email if you have provided
            one. If you disagree with these changes, you may cancel your account
            at any time. If you keep using our Services, you consent to any
            amendment of this Privacy Notice.
            <br />
            <br />
            CONTACT
            <br />
            You can contact our privacy team with any questions or concerns at
            the address below.
            <br />
            <br />
            Please contact our privacy team with any questions or concerns
            regarding this Privacy Notice:
            <br />
            <br />
            Uzmandata LLC., 312 W 2nd St Unit #A1604 Casper, WY 82601, United
            States info@appomark.com
            <br />
            <br />
            If you have any questions or concerns regarding our privacy notice,
            or if you believe our privacy notice or applicable laws relating to
            the protection of your personal information have not been respected,
            you may file a complaint with our privacy team listed above. We will
            respond to let you know when you can expect a further response. We
            may request additional details from you regarding your concerns and
            may need to engage or consult with other parties in order to
            investigate and address your issue. We may keep records of your
            request and any resolution.
          </Modal>
          <Modal
            width={1000}
            title="GDPR"
            visible={isOpenGDPR}
            onCancel={() => setOpenGDPR(false)}
            footer={null}
          >
            Appomark is committed to data protection and welcomes the General
            Data Protection Regulation (GDPR), which was adopted by the European
            Union (EU) and goes into effect May 25, 2018.
            <br />
            <br />
            What is GDPR?
            <br />
            The GDPR was created to harmonize data privacy laws across Europe.
            It protects and empowers all EU citizens data privacy and changes
            the way businesses handle data privacy.
            <br />
            <br />
            Does GDPR affect your business?
            <br />
            The GDPR applies to any organization inside or outside the EU who is
            marketing goods or services to, and/or tracking the behaviors of
            customers within the EU. Basically, if you do business with citizens
            and residents of the EU that involves the processing or storage of
            their personal data, this applies to you.
            <br />
            <br />
            Appomark and GDPR
            <br />
            Your customer data is a top priority for Appomark. With millions of
            customers making appointments every month through our software, we
            care deeply about their privacy and data security.
            <br />
            <br />
            Appomark, Inc. collects data to operate effectively and provide
            better quality experiences. Below, you will find a list of our
            products, services, and processes that gather personal data, our
            purpose and legal basis for processing that information, who we
            share that information with, and how long we hold that information.
            <br />
            <br />
            Description of Product, Service, or Process
            <br />
            Appomark is cloud-based software that helps salon, spa, and fitness
            professionals run their business successfully. We offer everything
            from online scheduling to email marketing. For more information
            visit Appomark.com/pro.
            <br />
            <br />
            Categories of Personal Data
            <br />
            Appomark handles the following categories of personal data:
            <br />
            <br />
            Identifying information (e.g. gender and name). Personal history
            data (e.g. appointments/classes, memberships, packages, gift
            certificates, and products). Social and contact information (e.g.
            address, email address, phone numbers, address, and birthday).
            Financial data (e.g. sales data and credit card information).
            Tracking data (e.g. customer's IP location when booking online or
            via the app).
            <br />
            Category of Data Subjects
            <br />
            Appomark manages information for users of the software. This
            includes employees of businesses as well as their customers.
            <br />
            <br />
            Purpose of Processing
            <br />
            Data is used for authenticating user accounts, tracking sales data,
            booking appointments, sending communications related to services,
            and email marketing.
            <br />
            <br />
            Legal Basis for Processing
            <br />
            Appomark has a legitimate business interest in handling the
            information on behalf of our customers and their end-users.
            <br />
            <br />
            Automated Processing or Profiling
            <br />
            Automated processing does not occur.
            <br />
            <br />
            Categories of Recipients who Receive this Personal Data
            <br />
            Cloud service providers are used to store user data and payment card
            processors are used to process credit card payments.
            <br />
            <br />
            Where is Data Stored
            <br />
            Data is stored on servers located in the United States.
            <br />
            <br />
            Retention Period
            <br />
            Forever, unless Right to be Forgotten (right for individuals to have
            personal data erased) is requested by business or end user.
            <br />
            <br />
            What do we do to ensure data protection for you and your customers?
            <br />
            All transmissions from your computer or mobile app are encrypted via
            HTTPS (SSL). We use cryptography hash functions to protect your
            information. All credit card transactions are secured through
            PCI-Compliant credit card gateway and banking networks. Our
            application data is hosted at data centers where rigorous security
            includes on-site 24/7 staff, alarm systems, card key access, CCTV
            archived video, fully redundant power supplies, multiple backup
            generators, hosts of Tier 1 Internet providers, and laser-based
            early smoke detection.Our data centers maintain security
            certifications including ISO 27001, SOC 1 & 2 Type 2, FedRAMP, and
            PCI Level 1. For security reasons, we do not disclose any further
            information regarding our system and technology we use, but rest
            assured that we use enterprise-class hosting and security partners
            that are all GDPR complaint.
            <br />
            <br />
            What do you need to do?
            <br />
            While GDPR is a European Union (EU) Regulation, it can affect you if
            you do business with customers from the EU. GDPR stipulates that
            customers have the right to access their data or “be forgotten” (be
            permanently deleted) from your databases.
            <br />
            <br />
            If you receive such a request from your customers, you can simply
            Click Here to fill out the form and we will process that request for
            you. You will not lose customer transaction data for your business
            reports, but all data that can identify that customer such as their
            name, address, email address, phone numbers, address and birthday as
            well as credit card information that may be on file will be removed
            from our databases.
            <br />
            <br />
            Please remember that customers submitting a request to be forgotten
            may have active memberships, packages, gift certificates,
            prepayments for appointments and classes and IOUs. They may also
            have purchased merchandise that may be returned in the future. It
            will be up to you to decide to Void, Refund, Collect or do nothing
            with these items. It will also be your responsibility to delete any
            future appointments or classes booked by this customer.
            <br />
            <br />
            Ultimately, you are responsible for following the GDPR and ensuring
            that you and your employees are compliant. This may include
            notifying individuals of how you handle their personal information,
            obtaining their consent when required, and processing their requests
            to either access their personal data or erasing their personal data
            (see Right to Access and Right to be Forgotten).
            <br />
            <br />
            What about Email Messages?
            <br />
            There are two types of emails in Appomark and are defined as
            follows:
            <br />
            <br />
            Transactional emails – these are sent in response to a customer's
            interaction with a web site or an app and are defined in strictly
            functional terms. Examples include password resets, shipping
            notifications, receipts, legal notices, appointment reminders &
            confirmations, etc. Opt-In is Not required for these types of
            emails. Marketing emails – these are sent to a list of customers who
            have opted in for promotional content. Examples include Daily Deals,
            promotions, sales offers, newsletters, new product updates, and
            emails designed to increase user engagement, etc. Existing
            Customers:
            <br />
            On May 25th, all your existing customer records will automatically
            have the Promotional Emails preference turned OFF.
            <br />
            <br />
            In addition, all your customers will automatically receive an email
            from your business asking them if they would like to turn ON
            Promotional Emails so they can begin receiving them.
            <br />
            <br />
            New Customers:
            <br />
            To comply with GDPR, starting May 25th, any new customers entered or
            imported into Appomark will have the Promotional Emails preference
            turned OFF by default. Here's what to do to encourage them to
            receive promotional emails:
            <br />
            <br />
            Customers manually added to Appomark by your staff : Each customer
            will automatically receive a Welcome Email from your business and
            will be given the option to turn ON Promotional Emails. Customers
            imported into Appomark : Simply go to Marketing after the import,
            then click on Invite Customers to send an automatic Welcome Email
            out and to give each customer the option to turn ON Promotional
            Emails. Customers who add themselves to Appomark : These customers
            will be prompted to turn ON Promotional Emails. Reminders and
            Confirmations: Don't worry about appointment reminders,
            confirmations and other transactional emails. These are not
            restricted by GDPR and will continue to be sent out to customers
            whether or not they choose to opt out of promotional emails.
            <br />
            <br />
            Customers will continue to have the ability Opt-Out of marketing
            emails anytime by either updating email marketing preferences in
            their account or simply by clicking on Unsubscribe at the bottom of
            any email marketing they receive from you.
            <br />
            <br />
            Remember, the GDPR only applies to your customers who are citizens
            or residents of the EU.
            <br />
            <br />
            What about SMS/Text Messages?
            <br />
            Since Appomark does not do Text Marketing and all text messages are
            transactional only, there are no issues.
            <br />
            <br />
            Right to Access
            <br />
            The GDPR stipulates that a person has the right to a copy of their
            personal data. With Appomark, a customer has full access to their
            personal profile and can update, change or delete information at any
            time.
            <br />
            <br />
            Right to be Forgotten
            <br />
            The GDPR stipulates that a person has a right to the erasure of
            personal data. We will process your customers&pos; requests to “be
            forgotten” for you. These are the steps we follow:
            <br />
            <br />
            You or your customer can simply Click Here to fill out the “be
            forgotten” request form. Appomark will send the requester (you or
            your customer) a confirmation email from info@Appomark.com. Once the
            requester confirms the “be forgotten” request it will be processed.
            Questions? If you have any questions regarding GDPR, you can simply
            email info@appomark.com.
          </Modal>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, 0],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '130px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
