import Process1 from '../../assets/image/saasModern/process-1.svg';
import Process2 from '../../assets/image/saasModern/process-2.svg';
import Process3 from '../../assets/image/saasModern/process-3.svg';

import FeatureIcon1 from '../../assets/image/saasModern/icon-1.png';
import FeatureIcon2 from '../../assets/image/saasModern/icon-2.png';
import FeatureIcon3 from '../../assets/image/saasModern/icon-3.png';
import FeatureIcon4 from '../../assets/image/saasModern/icon-4.png';
import FeatureIcon5 from '../../assets/image/saasModern/icon-5.png';
import FeatureIcon6 from '../../assets/image/saasModern/icon-6.png';

import CiroEkran from '../../assets/image/saasModern/ciroekran.png';
import RandevularimEkran from '../../assets/image/saasModern/googleyorumu.png';
import RandevuAlEkran from '../../assets/image/saasModern/randevualekran.png';
import MusterilerimEkran from '../../assets/image/saasModern/cagirmaekranimusteri.png';

import AuthorOne from '../../assets/image/saasModern/author-1.jpg';
import AuthorTwo from '../../assets/image/saasModern/author-2.jpg';
import AuthorThree from '../../assets/image/saasModern/author-3.jpg';

import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { users } from 'react-icons-kit/icomoon/users';
import { manWoman } from 'react-icons-kit/icomoon/manWoman';
import { clock } from 'react-icons-kit/icomoon/clock';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Benefits',
    path: '#feature_section',
    offset: '0',
  },
  {
    label: 'Pricing',
    path: '#pricing_section',
    offset: '0',
  },
  {
    label: 'Testimonials',
    path: '#testimonial_section',
    offset: '0',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '0',
  },
  {
    label: 'About',
    path: '#hakkimizda_section',
    offset: '0',
  },
];

export const PROCESS_ITEMS = [
  {
    image: Process2,
    title: 'Register for Free',
    description:
      'Start your 7-day free trial without a credit card by simply typing your phone number and password.',
  },
  {
    image: Process1,
    title: 'Create Your Appointment',
    description:
      'Book your first appointment within 10 seconds and automatically remind your customers of their appointments via voice call, sms and email.',
  },
  {
    image: Process3,
    title: 'Request a Review',
    description:
      'Click on the created appointment and send a Google review link to your client.',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Business Account',
    description:
      'For those who want to follow their appointments, customers and turnovers and gain more customers.',
    price: '$297',
    priceLabel: 'Monthly',
    buttonLabel: '7 DAY FREE TRIAL',
    url: 'https://platform.appomark.com/register',
    listItems: [
      {
        content: 'Manage your appointments, turnovers, customers and expenses.',
      },
      {
        content:
          'Gain new customers thanks to the Google review request feature.',
      },
      {
        content:
          'Easily see your customers who have not made an appointment for a while and win them back with one click.',
      },
      {
        content:
          'Make your customers feel special with birthday celebrations and ParaPuan features.',
      },
      {
        content: 'Much more is waiting for you inside...',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    expend: true,
    title: 'What is a Appomark?',
    description:
      'Appomark; It is a cloud-based appointment, accounting, and customer management system.',
  },
  {
    title: 'Is Appomark paid?',
    description:
      'Appomark offers you a 7-day trial version. As soon as you register, it simply places your customers, turnover, debts, and expenses with a few examples so that you can get used to the system more easily. You will only have to examine and implement the system.',
  },
  {
    title: 'Can my assistants use this program?',
    description:
      'Appomark offers special solutions for assistants, consultants, interns, and bosses. You can easily authorize them by creating special accounts in the settings.',
  },
  {
    title: 'Who do I contact if I have a problem?',
    description:
      'Appomark tries to keep the support line active all the time. You can easily contact the representatives from within the platform.',
  },
  {
    title: 'How safe is my data?',
    description:
      'We store your data on highly secure Amazon servers using the latest technologies.',
  },
  {
    title: 'Do you care about user experience?',
    description:
      'Appomark improves itself every day according to the feedback it receives from its customers.',
  },
  {
    title: 'How will I increase my customers with Appomark?',
    description:
      "Appomark provides you with the data obtained as a result of your transactions in the most beneficial way. In line with this data, the marketing solutions offered to you will help you win back your customers who haven't made an appointment for a long time with Google Reviews, online customer acquisition, bulk SMS sending, and the Voice Call feature.",
  },
  {
    title: 'Is Appomark a program installed on the computer?',
    description:
      'Appomark does not require installation because it is a cloud-based software. You can connect from anywhere and from any device (phone, computer, tablet) where there is an internet connection.',
  },
  {
    title: 'How often do you do maintenance, and will you interrupt my work?',
    description:
      'It may go into maintenance mode after business hours at night. If there is an urgent problem that needs to be solved, we have one-time maintenance for 15 minutes during the day. We work with precision so as not to disrupt your work.',
  },
];

export const FOOTER_WIDGET = [
  {
    title: 'Quick Links',
    menuItems: [
      {
        url: '#',
        text: 'Contact Us',
      },
      {
        url: '#',
        text: 'Work With Us',
      },
    ],
  },
  {
    title: 'Documents',
    menuItems: [
      {
        url: '#',
        text: 'User Agreement',
      },
      {
        url: '#',
        text: 'Privacy Policy',
      },
      {
        url: '#',
        text: 'GDPR',
      },
    ],
  },
];

export const FEATURES = [
  {
    icon: FeatureIcon1,
    title: 'Remind Your Appointments',
    description:
      "Quickly create your appointments in a few seconds on the same screen and automatically remind your customers. You don't need to waste your time going here and there anymore. Give your assistant an easy and simple user experience.",
  },
  {
    icon: FeatureIcon2,
    title: 'Get Positive Google Reviews',
    description:
      "Request reviews from your happy clients with the Google Review feature. As the number of reviews for your business has increased, your potential customers will see and prefer you more without ads on Google. Well, wouldn't you prefer the businesses with the best reviews?",
  },
  {
    icon: FeatureIcon3,
    title: 'Increase Customer Loyalty',
    description:
      'Increase customer loyalty by defining money points for your clients at the rates you set.',
  },
  {
    icon: FeatureIcon4,
    title: 'Manage Your Customers',
    description:
      "Easily see your customers' past appointments, which ones they came to, what they did, how much they earned in total, how many days they haven't made an appointment, their debt, their birthday, and much more.",
  },
  {
    icon: FeatureIcon5,
    title: 'Increase Your Revenue with SMS Marketing',
    description:
      'Increase your turnover by using many alternative SMS marketing methods such as Bulk and Individual SMS services.',
  },
  {
    icon: FeatureIcon6,
    title: 'Get Instant Support',
    description:
      'If you have any problems or difficulties, we help you by reaching out as soon as possible. Take your Appomark experience to the next level thanks to the feedback we receive from you.',
  },
];

export const SCREENSHOTS = [
  {
    icon: clock,
    title: 'Appointment Page',
    image: RandevuAlEkran,
  },
  {
    icon: manWoman,
    title: 'Google Review',
    image: RandevularimEkran,
  },
  {
    icon: users,
    title: 'Clients',
    image: MusterilerimEkran,
  },
  {
    icon: ic_monetization_on,
    title: 'Accounting',
    image: CiroEkran,
  },
];

export const TESTIMONIALS = [
  {
    title: 'I Became the Highest-reviewed Coiffeur in My City.',
    review:
      'Before I started using the Appomark software, I only had 4 reviews in my Google Business account. Right now, I have 500+ real reviews on my listing. Thanks to these positive reviews, I am getting new ad-free customers on Google every day. I have used many different programs, but I have not seen any software that provides these features.',
    name: 'Sadettin Yılmaz',
    designation: 'Coiffeur',
    avatar: `${AuthorOne}`,
  },
  {
    title: 'I Reach New Patients and I Can Easily Manage My Appointments',
    review:
      'My assistants could not use the complex programs I used to use. The Appomark interface is so simple and useful that I can arrange my appointments in seconds. The reminder messages that I send to my patients with my doctor ID and name increase the prestige of my clinic. Since I started getting Google reviews from my happy patients, our appointments have increased.',
    name: 'Berk Erol',
    designation: 'Dentist',
    avatar: `${AuthorTwo}`,
  },
  {
    title: 'I Can Remind an Appointment by Voice Call',
    review:
      'Appomark calls my clients before the appointment and reminds them of the appointment. Now my clients do not miss their appointments, so I increased the show-up rate in my salon.',
    name: 'Ayşen Mergen',
    designation: 'Beauty Salon Owner',
    avatar: `${AuthorThree}`,
  },
];
